.search-wrap {
  padding: $padding5 * 3;
  position: $position-relative;
  margin-bottom: $margin5 * 3;
  @include border-radius(4px);
  @include box-shadow(
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12)
  );
  background: $white;

  .row {
    margin-right: -$padding5;
    margin-left: -$padding5;
    .col-sm-6,
    .col-md-2,
    .col-xs-6,
    .col-xs-12 {
      padding-right: $padding5;
      padding-left: $padding5;
      // &:first-child{
      //     padding-left:$padding5*2;}
    }
  }
  label {
    font-size: $font12;
    color: $black-54;
  }
  .css-1okebmr-indicatorSeparator {
    display: $display-none;
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    height: 38px;
  }
  .css-1wa3eu0-placeholder {
    font-size: $font12;
    color: $black-54;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 8px 4px;
  }
  .advancesearch {
    font-size: $font12;
    position: $position-absolute;
    left: 50%;
    bottom: -23px;
    background: $white;
    @include border-radius(0 0 100px 100px);
    margin-left: -120px;
    padding: $padding0 + 2 $padding5 * 3;
    @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, 0.2));
    opacity: 1;

    &:hover {
      @include box-shadow(0 2px 1px -1px rgba(0, 0, 0, 0.2));
    }
  }
}
.search-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .searchitems {
    width: 100%;
    padding: $padding0 $padding5;
  }
}
.search-wrap,
.inventory-wraper > .left-bar {
  ._2OR24XnUXt8OCrysr3G0XI {
    background: $white;
  }
}
.search-wrap,
.inventory-wraper > .left-bar,
.edit-requirements .col-sm-6 {
  ._2OR24XnUXt8OCrysr3G0XI {
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    height: 38px;
    background: $white;
  }
}

._3fD5brWjGxPOXWAlZj16mH {
  margin-right: 5px !important;
}
._3vt7_Mh4hRCFbp__dFqBCI .optionListContainer .optionContainer {
  li {
    &:hover {
      background: rgba($headerbg, 0.2);
      color: $black;
    }
  }
}
@media (max-width: $breakpoint-tab1) {
  .search-wrap,
  .inventory-wraper > .left-bar,
  .edit-requirements .col-sm-6 {
    ._2OR24XnUXt8OCrysr3G0XI {
      overflow: inherit;
      white-space: inherit;
      overflow-x: inherit;
      height: inherit;
      background: $white;
    }
  }
}
@media (min-width: $breakpoint-tab1) {
  .search-wrap {
    padding-bottom: $padding0;
  }
  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .searchitems {
      width: 20%;
      &.doublew {
        margin-bottom: 15px;
      }
      &.addConversionBtns {
        width: 50%;
        .btn + .btn {
          margin-left: $margin5 * 2;
        }
      }
      &.form-group {
        > .form-group {
          margin-bottom: 0px;
          .form-field {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
@media (min-width: $breakpoint-tab2) {
  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .searchitems {
      width: 16.66666%;
    }
  }
}

/* Manage Result*/
.result-wrap {
  padding: $padding5 * 3 $padding0 $padding5 * 3 $padding0;
  position: $position-relative;
}

.table {
  .form-control {
    font-size: $font14;
  }
}
.car-price {
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .search-wrap {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0;
    border: 0;
    .btn.btn-primary {
      width: calc(50%);
    }
    .btn.btn-default {
      width: calc(50% - 10px);
    }
    .advancesearch {
      position: initial;
      margin-top: $margin5 * 3;
    }
  }

  .search-wrap:before {
    content: "\e908";
    width: 24px;
    height: 24px;
    font-family: "dc" !important;

    z-index: 1000;
    font-size: 24px;
  }
}
.num-words {
  text-transform: capitalize;
  opacity: 0.87;
  font-size: $font12;
}

/*  -------- Lead Details ---- */
.main_details {
  background: $white;
  transform: translateY(0px);
  @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  position: $position-relative;
  z-index: 10;
  left: 0;
  right: 0;
  @include transition(all 0.3s);
  .fixed {
    position: $position-fixed;
    top: 0;
  }
}

.maindetail-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .maindetail-item {
    padding: $padding5 * 3;
  }
}
.subheading {
  color: $black-54;
  font-size: $font12;
  font-weight: 400;
  margin-bottom: $margin5 * 2;
  font-style: italic;
}
.sub-value {
  color: $black-87;
  font-size: $font14;
  font-weight: 400;
  text-transform: capitalize;
}
.sub-value-list {
  li {
    display: $display-inline-block;
    padding-right: $padding5 * 3;
    position: $position-relative;
    color: $black-87;
    text-transform: capitalize;
    &:before {
      content: "";
      height: 4px;
      width: 4px;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      border-radius: 15px;
      left: -10px;
      top: 9px;
    }
    &:first-child {
      &:before {
        display: none;
        content: none;
      }
    }
  }
}
.detail-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .detail-item {
    padding: $padding5 * 3;
  }

  .subheading {
    margin-bottom: $margin0 + 2;
    font-style: normal;
  }
}
.cityuserleave {
  min-width: 110px;
  // *, div{border: none !important;}
  // .css-yk16xz-control{min-height: auto;}
  // .css-1hwfws3{font-size: $font14;}
  // .css-2b097c-container .css-tlfecz-indicatorContainer{padding: 0px;}
}
.name-input {
  @include border-radius(2px);
  border: none;
  border-bottom: dashed 1px $black-12;
  outline: none;
  &:hover {
    border-bottom: dashed 1px $black-37;
  }
  &:focus {
    border-bottom: dashed 1px $blue-color;
  }
}
.requirements-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    display: inline-flex;
    padding-right: $padding5;
    white-space: nowrap;
    margin-bottom: $margin5;
    &:before {
      content: "•"; /* Insert content that looks like bullets */
      padding-right: $padding5;
      color: $black-37;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
}
.edit-requirements {
  label {
    font-size: $font12;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  [class|="col-sm"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.css-1wa3eu0-placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.searchpanel {
  margin-top: $margin5 * 2;
  border: solid 1px $black-12;
  background: $white;
  @include border-radius(3px);
  .form-control {
    border: 0px;
  }
  .btn-search {
    padding: 2px 10px;
    font-size: $font20;
    color: $primary-color;
    &:hover {
      color: $primary-color;
      background: $black-3;
    }
  }
}
.table {
  &.table-category {
    td {
      vertical-align: middle;
    }
  }
}
.card-headflex {
  display: flex;
  cursor: pointer;
  .timelinelink {
    margin-left: auto;
  }
  &:after {
    content: "\e917";
    font-family: "dc" !important;
    font-size: $font20;
  }
}
.label-sm {
  padding: $padding0 + 1 $padding5;
}
.assignedcar-table {
  td {
    font-size: $font12;
    position: $position-relative;
  }
  label {
    font-size: $font12;
    white-space: nowrap;
    margin-bottom: $margin5;
  }
  .form-control {
    padding: 2px 5px;
    font-size: $font12;
    height: 24px;
  }
}
.searchcars-pane {
  position: $position-relative;
}
.summaryWrapper {
  position: sticky;
  bottom: 0px;
  z-index: 2;
}
.react-datepicker-popper {
  z-index: 999 !important;
}
.summary-panel,
.timeline-panel {
  background: $white;
  width: $width-full;
  // position: $position-absolute;
  // bottom: 0px;
  .panel-body,
  .card-body {
    max-height: 0px;
    @include transition(all 0.3s);
    overflow: hidden;
    padding: 0 15px;
  }
  .panel-heading,
  .card-heading {
    position: $position-relative;
    padding-right: 45px;
    cursor: pointer;
    border-top: solid 1px $black-12;
    &:after {
      content: "\e917";
      font-family: "dc" !important;
      font-size: $font20 + 10;
      position: absolute;
      opacity: 0.54;
      top: 5px;
      right: 10px;
      @include transition(all 0.2s);
    }
  }

  &.open {
    .panel-heading,
    .card-heading {
      position: $position-relative;
      &:after {
        @include rotate(180);
      }
    }
    .panel-body,
    .card-body {
      max-height: 1800px;
      overflow: inherit;
      padding: 15px;
    }
  }
  .addmore {
    margin-top: -20px;
  }
}
.text-link {
  color: $blue-color;
  font-size: $font12;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}
.timeline {
  &.large-timeline {
    .timeline-badge {
      width: 24px;
      height: 24px;
      line-height: 20px;
      margin-left: -29px;
      border: solid 1px $black-3;
      background: #fff;
      top: 12px;
      i {
        font-size: $font20;
      }
    }
    .edit-secR {
      width: 57%;
    }
    .edit-secR20 {
      width: 23%;
      float: left;
      padding-left: $padding5 * 6;
    }
  }

  &.activity-timeline {
    .timeline-badge {
      width: 24px;
      height: 24px;
      line-height: 20px;
      margin-left: -29px;
      border: solid 1px $black-3;
      background: #fff;
      top: 12px;
      i {
        font-size: $font20;
      }
    }
  }
}
.css-26l3qy-menu {
  z-index: 999 !important;
}
.tab-content {
  .tab-pane {
    position: $position-relative;
    display: none;
    &.active {
      display: block;
    }
  }
}
.price-pop {
  position: $position-relative;
  .bs-popover-bottom {
    display: none;

    margin-top: 20px;
    margin-left: -20px;
    .arrow {
      left: 22px;
    }
  }
  &:hover {
    .bs-popover-bottom {
      display: block;
    }
  }
}

.price-popover {
  position: $position-relative;
  display: $display-inline-block;
  .bs-popover-bottom {
    min-width: 300px;

    margin-top: 20px;
    margin-left: -20px;
    .arrow {
      left: 15px;
    }
  }
}
@media (min-width: 992px) {
  .price-popover {
    .bs-popover-bottom {
      min-width: 600px;
    }
  }
}
.tt-label {
  opacity: 0.54;
  font-size: $font12;
}
.tt-detail {
  font-size: $font14;
  color: $black-87;
}
.recCarDetails {
  background: #e9f1f7;
  position: $position-relative;

  &:hover {
    background: #e9f1f7 !important;
  }

  .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $popover-arrow-margin;
    top: -13px;
    left: 80px;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
    &::before {
      border-bottom-color: $popover-arrow-outer-color;
      border-width: 0px 13px 13px 13px;
    }
    &::after {
      border-bottom-color: #e9f1f7;
      border-width: 0px 12px 12px 12px;
      top: 1px;
      left: 1px;
    }
  }
}
#recmimg {
  .carousel__slide {
    min-height: 166px;
  }
  .carousel__arrow {
    margin-top: 0px;
  }
  .carousel__arrow--left {
    left: 0px;
  }
  .carousel__arrow--right {
    right: 0px;
  }
}

.chip-set {
  display: flex;
  flex-wrap: wrap;
  padding: $padding5;
  align-items: center;
  .chips {
    display: inline-flex;
    position: $position-relative;
    background-color: $black-12;
    color: $black-87;
    height: 32px;
    align-items: center;
    padding: $padding0 $padding5 * 2;
    border-width: 0px;
    outline: none;
    cursor: $cursor-pointer;
    @include border-radius(16px);
    margin-right: $margin5;

    &:hover {
      text-decoration: line-through;
    }
  }
}
hr {
  border: none;
  margin: 0px;
  border-bottom: solid 1px $black-12;
  margin-bottom: $margin5 * 3;
}
/* inventory-wraper */
.inventory-wraper {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  .left-bar {
    background: $black-3;
    width: 17%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    padding: 10px;
    border-right: solid 1px $black-12;
    padding-bottom: 120px;

    label {
      font-size: $font12;
    }
    .col-sm-6,
    .col-sm-12 {
      margin-bottom: $margin5;
    }
    .filtersBtns {
      position: fixed;
      bottom: 0px;
      z-index: 9;
      background: #e9ebee;
      width: calc(17% + 4px);
      padding: $padding5 * 2;
      text-align: center;
      @include box-shadow(0px -2px 8px 0px rgba(0, 0, 0, 0.3));
    }
  }
  .f14 {
    font-size: $font14;
  }

  .right-bar {
    background: $white;
    width: 83%;
    margin-left: 17%;

    .right-bar-body {
      padding: $padding5 * 2;
      background: $white;
      padding-top: 68px;
      position: $position-relative;
    }
  }
}
.invFilter {
  position: fixed;
  z-index: 9;
  display: block;
  background: #f7f7f7;
  border-bottom: solid 1px $black-12;
  width: calc(100% - 18%);

  .invFilterwrap {
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding: $padding5 $padding5 * 2;

    .gs_control {
      margin-bottom: $margin0;
    }
    .filterresult {
      margin-left: auto;
    }
  }
}

/*Report Section*/
.deliveryTable {
  thead,
  tbody {
    th,
    td {
      vertical-align: middle;
      word-break: initial;
    }
  }
  .totalvalue {
    td {
      background: $black-3;
      font-weight: $fontmedium;
    }
  }
}
.savefilterwrap {
  display: flex;
  align-items: center;
  .filtername {
    label {
      display: none;
    }
    .form-field {
      margin-bottom: 0px;
    }
  }
}
.mrg-lauto {
  margin-left: auto;
}
.mrg-rauto {
  margin-right: auto;
}
.text-white {
  color: $white;
}

/*Reschedule css*/
.rescheduleForm {
  .card-title {
    font-weight: bold !important;
  }
  .formBox {
    border: solid 2px $black-37;
  }
}
.rescheduleList {
  > li {
    display: flex;
    align-items: baseline;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px $black-12;

    i {
      margin-right: 10px;
      opacity: 0.54;
      font-size: 16px;
    }
    .carName {
      font-weight: bold;
    }
    .carPrice {
      font-size: 18px;
      font-weight: bold;
    }
    .carDetails {
      li {
        display: $display-inline-block;
        padding-right: $padding5 * 3;
        position: $position-relative;
        color: $black-87;
        text-transform: capitalize;
        font-size: $font12;
        &:before {
          content: "";
          height: 4px;
          width: 4px;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          border-radius: 15px;
          left: -10px;
          top: 9px;
        }
        &:first-child {
          &:before {
            display: none;
            content: none;
          }
        }
      }
    }
  }
}
.nolabel {
  label {
    display: none !important;
  }
}
.feedbackForm {
  .form-group {
    margin-bottom: 30px;
    label.headLabel {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
.ratingbox {
  [class^="ic-"],
  [class*=" ic-"] {
    font-size: 24px;
    margin-right: 15px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    > label {
      width: 34%;
    }
  }
}

.rating10 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .ratingPoints {
    width: 24px;
    height: 24px;
    text-align: center;
    display: inline-block;
    align-items: center;
    border-radius: 50%;
    border: solid 2px $black-12;
    margin-right: 6px;
    line-height: 21px;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.filled {
      background: $primary-color;
      border-color: $primary-color;
      color: $white;
    }
  }
}
.rangeoutput {
  position: relative;
  output {
    &:after {
      content: "\e922";
      font-family: "dc";
      color: $primary-color;
      position: absolute;
      bottom: -30px;
      font-size: 40px;
    }
  }
}
@media (min-width: $breakpoint-tab1) {
  .ratingbox {
    [class^="ic-"],
    [class*=" ic-"] {
      font-size: 30px;
    }

    li {
      > label {
        width: 20%;
      }
    }
  }

  .rating10 {
    justify-content: flex-start;

    .ratingPoints {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      line-height: 33px;
    }
  }
}
