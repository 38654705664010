.card{background: $white;@include border-radius(4px);@include box-shadow(0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)); margin-bottom: $margin5*3; position:$position-relative;
    .card-heading{padding: $padding5*3;  border-bottom: solid 1px $black-12;position:$position-relative;
        .card-title{font-size: $font16+4;font-weight: $fontmedium; margin-bottom: $margin0; }
        .card-subhead{font-size: $font12; color: $black-54; margin-bottom: $margin0; margin-top: $margin5; }
}
    .card-body{padding: $padding5*3;}
    .card-footer{padding: $padding5*3; border-top:solid 1px $black-12;}
    &.card-success{@include border-radius(5px);
        .card-heading{background: $success-color; color: $white;@include border-radius(5px 5px 0px 0px);}
    }
    &.card-danger{@include border-radius(5px);
        .card-heading{background: $error-color; color: $white;@include border-radius(5px 5px 0px 0px);}
    }
    &.card-secondary{@include border-radius(5px);
        .card-heading{background: $black-12; color: $white;@include border-radius(5px 5px 0px 0px);}
    }
}
.panel{@include border-radius(5px); margin-bottom: $margin5*3;@include box-shadow(0 3px 8px 0 rgba(0,0,0,0.2));position:$position-relative;
    .panel-heading{padding: $padding5*3; background: $black-3;  border-bottom: solid 1px $black-12;position:$position-relative;
        .panel-title{font-size: $font16+4;font-weight: $fontmedium; margin-bottom: $margin0; }
}
    .panel-body{padding: $padding5*3;}
    .panel-footer{padding: $padding5*3; border-top:solid 1px $black-12;}
    &.panel-gray{background: $bodycolor;}
}