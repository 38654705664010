.loginwrap {
  padding-top: $padding5 * 6;
  .loginlogo {
    display: inline-block;
  }
  .login-h1 {
    margin-bottom: $margin5;
    text-align: $text-center;
    padding-top: $padding0;
  }
  .login-p {
    margin-bottom: 30px;
    color: $black-54;
    text-align: $text-center;
  }
  .brand-logo {
    max-width: 80px;
  }
  .copyright {
    color: $black-37;
    font-size: $font12;
    text-align: $text-right;
  }
  .language-login {
    text-align: $text-left;
  }
  .forgotP {
    font-size: $font12;
    font-weight: $fontmedium;
    margin-bottom: $margin5;
  }
  .captchaimg {
    max-height: 36px;
    margin-top: 24px;
  }
  .reload {
    display: inline-block;
    width: 24px;
    font-size: 20px;
    margin-top: 24px;
  }
}
@media (max-width: $breakpoint-tab1) {
  .loginwrap {
    .captchaimg {
      margin-top: 0px;
      margin-bottom: 15px;
    }
    .reload {
      margin-top: 0px;
    }
  }
}
