//
// Basic Bootstrap table
//

.table {
  width: 100%;
  margin-bottom: $spacer;
  color: rgba($black, 0.67);
  background-color: $white; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: 8px;
    vertical-align: top;
    border-top: 1px solid $black-12;
    word-break: initial;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid $black-12;
  }

  tbody + tbody {
    border-top: 1px solid $black-12;
  }
}

//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $padding5;
  }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid $black-12;

  th,
  td {
    border: 1px solid $black-12;
  }

  thead {
    th,
    td {
      border-bottom-width: 1px;
      background: rgba($black, 0.08);
      text-align: $text-left;
    }
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: #fafafa;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    &:hover {
      td {
        background-color: rgba($blue-color, 0.04);
      }
    }
  }
}
.table-responsive {
  display: block;
  width: 100%;
}

.table-responsive > .table-bordered {
  border: 0;
}
@media (max-width: 767.98px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (min-width: 992px) {
  .table {
    word-break: break-word;
  }
}
