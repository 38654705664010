.pagination {
  display: flex;list-style: none;
  border-radius: .25rem; margin-bottom: $margin5*3; justify-content: flex-end;
}

.page-link {
  position: relative;
  display: block;
  padding: 8px 10px;
  margin-left: -1px;
  line-height: 1.25;
  
  background-color: $white;
  border: 1px solid $black-12;

  &:hover {
    z-index: 2;
    color: $primary-color;
    text-decoration: none;
    background-color: $black-3;
    border-color: $black-12;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow:none;
  }
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    }
  }
  &:last-child {
    .page-link {
      border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    }
  }

  &.active .page-link {
    z-index: 1;
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &.disabled .page-link {
    color: $black-37;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $white;
    border-color: $black-12;
  }
}


//
// Sizing
//
