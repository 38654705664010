.badge{
    padding: 2px 5px 2px;
    display: $display-inline-block;
    line-height: 1;
    font-size: $font12;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    @include border-radius(2px);
    // @include transition(all 0.3s);
&.badge-primary{background: $primary-color; color:$white;}
&.badge-secondery{background: $black-37; color:$white;}
&.badge-success{background: $success-color; color:$white;}
&.badge-error{background: $error-color; color:$white;}
}