
.timeline {list-style: none;
    padding: $padding0;
    position: $position-relative;
    &:before{
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        /* background-color: #eeeeee; */
        left: 8px;
        margin-left: -1.5px;
		border-left: 2px dashed #ddd;
		z-index: 1;
    }
	>li {
		margin-bottom:$margin0;
		position: $position-relative;
		>.timeline-badge {
			color: $black-87;
			width: 15px;
			height: 15px;
			line-height: 30px;
			font-size: 1.1em;
			text-align: center;
			position: $position-absolute;
			top: 15px;
			left: 25px;
			margin-left: -25px;
			background-color: $white;
			z-index: 1;
			border-top-right-radius: 50%;
			border-top-left-radius: 50%;
			border-bottom-right-radius: 50%;
			border-bottom-left-radius: 50%;
			border: 3px solid #e66437;
		}
		>.timeline-panel {
			width: 100%;
			position: relative;
			padding-left:$padding5*6 ;
		}
	}
	.timeline-heading {
		padding:$padding5*3 $padding0;
		border-bottom: 1px solid $black-12;
        
        .timeline-title {
            font-size: 14px;
            color: $black-87;
            font-weight: 400 !important;
            margin-bottom: $margin5;
        }
	}
}
.edit-secL {
	float: left;
	text-align: left;
	width: 20%;
	
}
.edit-secR {
	float: left;
	text-align: left;
	width: 80%;
	padding-left: $padding5*6;
	.cmnt{color: $black-54;
	font-size: $font12+1;}
	.statuspara{margin-bottom: $margin5;}
}
.timeline-panel {
	p {
		margin: 0;
	}
}
