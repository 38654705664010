@import "dropdown";
header {
  background: $headerbg;
  .brand-logo {
    float: $float-left;
    max-width: $width-full;
    max-height: 32px;
  }
  nav {
    float: $float-right;
  }
}
.App {
  padding-top: 54px;
}
@media (max-width: $breakpoint-tab1) {
  .App {
    padding-top: 0px;
  }
}
header {
  background: $headerbg;
  padding-right: $padding5 * 3;
  display: flex;
  align-items: start;
  width: $width-full;
  .navbar-brand {
    float: $float-left;
    max-width: $width-full;
    max-height: 32px;
    margin: 10px 10px;
    img {
      max-height: 32px;
    }
  }
  nav {
    margin-left: auto;
    float: inherit;
    .navbar-nav {
      li {
        float: $float-left;
        a {
          color: $white;
          padding: $padding5 * 3 $padding5 * 4;
          margin: 0px;
          display: $display-block;
          border-bottom: solid 3px transparent;
          &:hover,
          &:focus,
          &:active {
            color: $primary-color;
          }
          &.active {
            color: $primary-color;
            border-bottom: solid 3px $primary-color;
          }
        }

        img {
          display: $display-inline-block;
          margin-right: 8px;
        }
        > .dropdown-menu {
          li {
            .active {
              border-bottom: solid 3px transparent;
            }

            > .dropdown-menu {
              position: absolute;
              left: 100%;
              top: 0px;
              z-index: 1;
              display: none;
            }
            &.level2 {
              color: $black-37;
              &:after {
                content: "\e919";
                font-family: "dc" !important;
                position: absolute;
                top: 10px;
                right: 5px;
                display: inline-block;
              }
              &:hover {
                &.level2 {
                  color: $primary-color;
                }
                > a {
                  color: $primary-color;
                  background: $black-3;
                }
                > .dropdown-menu {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  .hamburger-icon {
    font-size: 42px;
    color: $white;
    display: none;
    @include transition(all 0.3s);
  }
  &.header-fixed {
    position: $position-fixed;
    @include z-index(99);
    top: 0px;
    @include box-shadow(0px 2px 8px 0px $black-37);
  }
}
.language-change {
  .btn {
    background: transparent;
    padding: $padding5 * 3 $padding5;
    color: $white;
    opacity: 0.54;
    border: none;
    outline: none;
    &.btn-default {
      color: $white;
    }
    &.btn-primary {
      opacity: 1;
      color: $primary-color;
    }
    &:hover {
      border: none;
      @include box-shadow(none);
      opacity: 1;
      color: $white;
    }
    &:focus {
      border: none;
      @include box-shadow(none);
      opacity: 1;
    }
  }
  &.language-login {
    text-align: $text-right;
    .btn {
      padding: $padding0 $padding5;
      color: $black;
      font-weight: 400;
      &.btn-default {
        color: $black;
      }
      &.btn-primary {
        opacity: 1;
        color: $primary-color;
        &:hover {
          color: $primary-color;
        }
      }
      &:hover {
        border: none;
        @include box-shadow(none);
        color: $black;
        opacity: 1;
      }
    }
  }
}
.userac {
  display: $display-inline-block;
  width: 18px;
  height: 18px;
  @include border-radius(50%);
  background: rgba($white, 0.3);
  text-align: $text-center;
  line-height: 18px;
  font-size: $font16;
  vertical-align: middle;
  margin-right: $margin5;
  i {
    opacity: 0.6;
  }
}
a {
  &:hover {
    .userac {
      color: $white;
    }
  }
}
.myac {
  margin-left: $margin5 * 6;
}

@media (max-width: $breakpoint-tab1) {
  header {
    .navbar-brand {
      img {
        max-height: 36px;
      }
    }
    nav {
      display: flex;
      overflow: $overflow-hidden;
      transform: translateX(-100%);
      position: $position-fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      background: $headerbg;
      z-index: 999;
      width: 0;
      @include transition(all 0.3s);

      .navbar-nav {
        width: $width-full;
        padding-top: 56px;

        li {
          display: block;
          float: initial;
          border-bottom: solid 1px $black-12;
          &.dropdown {
            a {
              .caret {
                float: right;
              }

              &:hover,
              &:focus {
                a,
                .userac,
                i {
                  color: $white;
                }
              }
            }
          }
          a {
            padding: $padding5 * 3;
            &:hover,
            &:focus,
            &:active {
              color: $primary-color;
            }
            &.active {
              color: $primary-color;
              border-bottom: solid 3px transparent;
            }
          }
          img {
            display: $display-inline-block;
            margin-right: 8px;
          }
          > .dropdown-menu {
            display: inherit;
            position: inherit;
            background: transparent;
            margin-bottom: $margin5 * 3;
            @include border-radius(0);
            @include box-shadow(none);
            li {
              color: $white;
              border: none;
              a {
                color: $white;
                opacity: 0.54;
                padding: $padding5;
                padding-left: $padding5 * 6;
              }
              .active {
                border-bottom: solid 3px transparent;
              }
            }
          }
        }
      }
      &.open {
        display: flex;
        height: $height-full;
        overflow-y: auto;
        transform: translateX(0);
        width: 80%;
        @include box-shadow(8px 0px 12px 0px $black-37);
      }
    }
    .hamburger-icon {
      display: initial;
      z-index: 1000;
    }
  }
  .myac {
    margin-left: $margin0;
  }
}
