@import "theme/include";
@import "theme/header";
@import "theme/footer";
@import "theme/loginpage";
@import "theme/ub";

@import url("https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap");
body {
  background: $bodycolor;
  font-size: $font14;
  color: $black-87;
  font-family: $font-family;
}
