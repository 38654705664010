.modal-open {
    // Kill the scroll on the body
    overflow: hidden;
  
    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    outline: 0;
    &.modal-lg{.modal-dialog{ max-width: $modal-lg; }
  }
  }
  .modal-dialog {
    position: relative;
    width: 90%;
    margin: 0.5rem auto;
    pointer-events: none;
    // height: $height-full;
    // padding: $padding5*6 $padding0 $padding0 $padding0;

    .modal.fade & {
      @include transition($modal-transition);
      transform: $modal-fade-transform;
    }
    .modal.show & {
      transform: $modal-show-transform;
    }
  }
  .modal-dialog-scrollable {
    display: flex; // IE10/11
    max-height: calc(100% - #{$modal-dialog-margin * 2});
  
    .modal-content {
      max-height: calc(100vh - #{$modal-dialog-margin * 2}); // IE10/11
      overflow: hidden;
    }
  
    .modal-header,
    .modal-footer {
      flex-shrink: 0;
    }
  
    .modal-body {
      overflow-y: auto;
    }
  }
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - #{$modal-dialog-margin * 2});
  
    // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
    &::before {
      display: block; // IE10
      height: calc(100vh - #{$modal-dialog-margin * 2});
      content: "";
    }
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    color: $black-87;
    pointer-events: auto;
    background-color: $bodywhite;
    background-clip: padding-box;
    border: solid 1px rgba($black , 0.2);
    @include border-radius(5px);
    @include box-shadow(0px 3px 6px 0px rgba($black ,0.4));
    // Remove focus outline from opened modal
    outline: 0;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    width: 100vw;
    height: 100vh;
    background-color: $black; cursor: pointer;
    opacity: 0.5;
    // display: none;
  
    // Fade for backdrop
    &.fade { opacity: 0; }
    &.show { opacity: 0.5; }
  }
  .modal-header {
    display: flex;
    align-items: flex-start; 
    justify-content: space-between;
    padding: $padding5*2;
    border-bottom: solid 1px $black-12;
    @include border-top-radius(5px);
    .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .5;
        cursor: $cursor-pointer;
        &:hover{opacity: .87;}
    }
    button.close{
      
      @include transition(all 0.3s);
      border: 0;
      -webkit-appearance:none;-moz--webkit-appearance:0;appearance: none;outline: none;}
    .close {
      padding: $padding0;
      width:24px;
      height:24px;
      line-height: 24px;
      margin: 0rem 0rem 0rem  auto; 
      background-color:transparent;
      @include border-radius(50%);
    &:hover{background-color:$black-12;}
  }
  }
  
  .modal-title {
    margin-bottom: 0; font-size: $font12*2;font-weight: $fontmedium;
    line-height: $line-height-base;
  }
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: $padding5*3;
  }
  
  .modal-footer {
    display: flex;
    align-items: center; 
    justify-content: flex-end; 
    padding: $padding5*3;
    border-top: solid 1px $black-12;
    @include border-bottom-radius(5px);
  
    // Easily place margin between footer elements
    > :not(:first-child) { margin-left: .25rem; }
    > :not(:last-child) { margin-right: .25rem; }
  }
  @media (min-width:$breakpoint-mob1){
    .modal-dialog {
        max-width: $modal-sm;
        margin: $margin5*2 auto;
      }
    
      .modal-dialog-scrollable {
        max-height: calc(100% - 60px);
    
        .modal-content {
          max-height: calc(100vh - 60px);
        }
      }
    
      .modal-dialog-centered {
        min-height: calc(100% - 60px);
    
        &::before {
          height: calc(100vh - 60px);
        }
      }
    
      .modal-content {
        @include box-shadow(0px 3px 6px 0px rgba($black , 0.4));
      }
    
      .modal-sm { max-width: $modal-sm; }
      
  }
  @media (min-width:$breakpoint-tab1){
    .modal-dialog {
        max-width: $modal-md;
         margin: 1.75rem auto;
      }
    }

  @media (min-width:$breakpoint-tab2){
    .modal-dialog {
        max-width: $modal-md;
        // margin: auto auto;
      }
    }
    @media (max-width:360px){
      .modal{
        .modal-dialog {
        max-width: 300px;
         margin: auto auto;
      }
      &.modal-lg, &.modal-md{
        .modal-dialog {
          max-width: 90%;
           
        }
      }
    }
    }

  // @media (min-width:$breakpoint-pc1){
  //   .modal-dialog {
  //       //max-width: $modal-lg;
  //       margin: auto auto;
  //     }
  //   }