.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: block;
  max-width: $popover-max-width;
  min-width: 200px;
  
  @include font-size($popover-font-size);
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  background-color: $white;
  background-clip: padding-box;
  border: solid 1px $black-12;
  @include border-radius(5px);
  @include box-shadow($popover-box-shadow);

  .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $popover-arrow-margin;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-popover-top {
  margin-bottom: $popover-arrow-height;

  > .arrow {
    bottom: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

    &::before {
      bottom: 0;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-outer-color;
    }

    &::after {
      bottom: $popover-border-width;
      border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-top-color: $popover-arrow-color;
    }
  }
}

.bs-popover-right {
  margin-left: $popover-arrow-height;

  > .arrow {
    left: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $popover-arrow-margin 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      left: 0;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-outer-color;
    }

    &::after {
      left: $popover-border-width;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-color;
    }
  }
}

.bs-popover-bottom {
  margin-top: $popover-arrow-height;

  > .arrow {
    top: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);

    &::before {
      top: 0;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-outer-color;
    }

    &::after {
      top: $popover-border-width;
      border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-color;
    }
  }

  // This will remove the popover-header's border just below the arrow
  .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: $popover-arrow-width;
    margin-left: -$popover-arrow-width / 2;
    content: "";
    border-bottom: $popover-border-width solid rgba($black-54 ,0.01);
  }
}

.bs-popover-left {
  margin-right: $popover-arrow-height;

  > .arrow {
    right: calc((#{$popover-arrow-height} + #{$popover-border-width}) * -1);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $popover-arrow-margin 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      right: 0;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
      border-left-color: $popover-arrow-outer-color;
    }

    &::after {
      right: $popover-border-width;
      border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
      border-left-color: $popover-arrow-color;
    }
  }
}

.bs-popover-auto {
  &[x-placement^="top"] {
    @extend .bs-popover-top;
  }
  &[x-placement^="right"] {
    @extend .bs-popover-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-popover-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-popover-left;
  }
}


// Offset the popover to account for the popover arrow
.popover-header {
  padding: $padding5 $padding5*2;
  margin-bottom: 0; // Reset the default from Reboot
  color: $black-87;
  background-color: $black-3;
  font-size: $font16;
  border-bottom: 1px solid darken($black-3, 5%);
  $offset-border-width: calc(#{$popover-arrow-margin} - #{$popover-border-width});
  @include border-top-radius(3px);
  .close{ float: right; margin-left: $margin5*6; opacity: 0.5;
    &:hover{opacity: 0.87;}
}

  &:empty {
    display: none;
  }
}

.popover-body {
  padding: $padding5*3 $padding5*3;
  color: $black-54;
}
